import * as React from "react"
import { Link, graphql } from "gatsby"
import { navigate } from "gatsby-link"

import Layout from "../components/layout"
import Seo from "../components/seo"

function encode(data) {
  const formData = new FormData()

  for (const key of Object.keys(data)) {
    formData.append(key, data[key])
  }

  return formData
}

const ContactPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`

  const [state, setState] = React.useState({ formError: false, formSubmitted: false })

  const handleChange = (e) => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const form = e.target
    fetch('/', {
      method: 'POST',
      body: encode({
        'form-name': form.getAttribute('name'),
        ...state,
        formSubmitted: undefined,
        formError: undefined
      }),
    })
      .then((resp) => {
        console.log(resp)
        if (!resp.ok) {
          setState({ ...state, formError: true })
        } else {
          setState({ ...state, formSubmitted: true })
        }
      })
      .catch(() => {
        setState({ ...state, formError: true })
      })
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="Contact" />
      <article>
        <h1> Contact </h1>
        {state.formError && <mark> There was an error sending the message, please try again later</mark>}
        {state.formSubmitted ? <div> Thanks, your message has been submitted and we will get back to you promptly. </div> :
          <form
            name="contact"
            data-netlify="true"
            data-netlify-honeypot="message2"
            method="POST"
            onSubmit={handleSubmit}
          >
            <label hidden>
              This field will make you go to spam. You shouldn't really be seeing this. Don't fill it out.
              <input name="message2" />
            </label>

            <input type="hidden" name="form-name" value="contact" />

            <div className="grid">
              <div>
                <label htmlFor="name"> Name </label>
                <input type="text" id="name" name="name" placeholder="Name" required onChange={handleChange} />
              </div>

              <div>
                <label htmlFor="email">Email Address</label>
                <input type="email" id="email" name="email" placeholder="Email address" required onChange={handleChange} />
              </div>
            </div>

            <label htmlFor="organisation">Political Organisation <sup><u>optional</u></sup></label>
            <input type="text" id="organisation" name="organisation" placeholder="Organisation" onChange={handleChange} />

            <label htmlFor="article">Article <sup><u>optional</u></sup></label>
            <input type="text" id="article" name="article" placeholder="Link to your work on Google Docs, Office, etc." onChange={handleChange} />

            <label htmlFor="message">Message <sup><u>optional</u></sup></label>
            <textarea
              id="message"
              name="message"
              placeholder="Any notes, feedback, questions or messages you wish to send to the Editorial Committee"
              required
              onChange={handleChange}
            />

            <button type="submit">Submit</button>
          </form>}
      </article>
    </Layout >
  )
}

export default ContactPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
